body {
  background: ivory;
}

.background {
  height: 100%;
  width: 100%;
}

.App {
  min-width: 320px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.link {
  color: black;
  text-decoration: none;
  border-bottom: 2px solid turquoise;
}

.link:hover {
  border-bottom: 2px solid darkturquoise;
}

.link:active {
  border-bottom: 2px solid #e74c3c;
}

.content-block {
  padding: 40px;
}

.content-block.home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-highlight {
  padding: 4px;
  background: #b3ffff;
}

.title-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  height: 106px;
}

.title-block .button {
  margin-bottom: 8px;
}

.text-block p {
  margin: 0;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 140px;
}

.button {
  font-size: 14px;
  background: white;
  padding: 8px;
  /* transition: .25s box-shadow linear; */
  text-decoration: none;
  color: black;
  border: 2px solid rgba(0, 0, 0, 0);
}

.button.back {
  margin-right: 4px;
}

.button:hover {
  box-shadow: 4px 4px turquoise;
}

.button:active {
  box-shadow: 4px 4px #e74c3c;
}

.text-block {
  background: #fefefe;
  padding: 10px;
}

@media (min-width: 385px) {
  .App {
    padding: 20px;
  }
}

@media (max-width: 640px) {
  body {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
