:root {
  --lighterProjectColor: #f5b7b1;
  --darkerProjectColor: #f1948a;
}

/* mobile first -- this goes up to 385px screen width*/

/* All of these (the containing box for each project, and
   contained image and description) should be the same size */
.project,
.project-image,
.project-text-box {
  height: 70vw;
  width: 70vw;
  transition: all 200ms ease-in-out;
}

.project.project-header {
  border: none;
  align-items: start;
  justify-content: end;
  margin-right: 8px;
  height: 120px;
}

.project h2 {
  margin-right: 2px;
}

.project .button {
  background: mistyrose;
}

@media (min-width: 385px) {
  .project.project-header {
    height: 70vw;
    align-items: end;
  }
}

/* Inside the description in each project box */
.link-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 70%;
}

.project-image {
  overflow: hidden;
}

.project {
  overflow: hidden;
}

.project-text-box {
  box-sizing: border-box;
  padding: 12px;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-text-box p {
  margin-top: 0;
  margin-bottom: 6px;
}

.project-name {
  padding: 1px;
  background: mistyrose;
}

.project .project-image {
  position: absolute;
  left: 0;
  bottom: 0;
}

.project .project-text-box {
  position: absolute;
  right: 0;
  top: 0;
}

.project-image {
  z-index: 10;
  background: #b3ffff;
}
/* This height offset lets you see the "sub-text" for each project while it's closed. */
.project-image .svg {
  width: 100%;
  height: calc(100% - 40px);
  transition: height 1s;
}
/* These are exceptions that need a bit more space for their text.*/
.project-image .branch-container.svg,
.project-image #starmap {
  height: calc(100% - 55px);
  transition: height 1s;
}

/* This covers the "sub-text" for each project when the project is "opened" */
.open .project-image .svg,
.open .project-image #starmap {
  height: 100%;
}

.sub-title {
  background: white;
  padding: 8px;
}

#lines:hover {
  stroke-width: 3px;
}

#romanholiday.project-image svg {
  width: 45%;
  transition: transform 1s;
  transition: margin-top 1s;
  margin-top: -90px;
}

.project.open #romanholiday.project-image svg {
  margin-top: -70px;
}

.project {
  margin: 4px;
  position: relative;
  cursor: pointer;
  border: 2px solid mistyrose;
}

.project-container {
  margin-top: 24px;
  padding-top: 10px;
  width: 82vw;
  max-width: 832px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* svg styles + hover styles */

/* roman holiday */
.branch {
  width: 45%;
  top: 4%;
  transition: all 1.2s;
  stroke-width: 0.5;
  stroke: #333;
}

.branch .leaf {
  fill: #fefefe;
  stroke: #333;
  stroke-width: 0.5;
}

.branch-right {
  right: 2px;
}

.branch-left {
  left: 2px;
}

#romanholiday:hover .branch-right {
  transition: all 1s;
  transform: rotate(12deg);
}

#romanholiday:hover .branch-left {
  transition: all 1s;
  transform: rotate(-12deg);
}

/* star map */
#starmap path {
  stroke-dashoffset: 400px;
  stroke-dasharray: 400px;
  transition: stroke-dashoffset 1s ease-in-out;
}

.starchart:hover #starmap path {
  stroke-dashoffset: 0;
}

#Star {
  transition: stroke-width 1s;
  transition: r 1s;
}

.starchart:hover #Star {
  r: 5px;
  stroke-width: 4;
}

.tiny-diamond,
.medium-diamond {
  fill: white;
}

/* cheese */

@keyframes sparkle {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.sparkle {
  opacity: 0;
}

#mousetrap {
  cursor: url(https://cdn.glitch.com/fbfbe80c-5dab-43e8-81ab-0a353618a87d%2Fmouse.png?v=1574279541232),
    pointer;
}

.mousetrap:hover #sparkles .tiny-diamond.one {
  animation: sparkle 0.75s infinite;
}

.mousetrap:hover #sparkles .tiny-diamond.four {
  animation: sparkle 0.5s infinite;
}

.mousetrap:hover #sparkles .tiny-diamond.two {
  animation: sparkle 0.6s infinite;
}

.mousetrap:hover #sparkles .tiny-diamond.three {
  animation: sparkle 1.6s infinite;
}

.mousetrap:hover #sparkles .tiny-diamond.four,
.mousetrap:hover #sparkles .tiny-diamond.seven {
  animation: sparkle 2s infinite;
}

.mousetrap:hover #sparkles .tiny-diamond.five,
.mousetrap:hover #sparkles .tiny-diamond.eight {
  animation: sparkle 1.4s infinite;
}

.mousetrap:hover #sparkles .tiny-diamond.six {
  animation: sparkle 1s infinite;
}

.mousetrap:hover #sparkles .medium-diamond.one {
  animation: sparkle 1.2s infinite;
}

.mousetrap:hover #sparkles .medium-diamond.two {
  animation: sparkle 2s infinite;
}

.mousetrap:hover #sparkles .medium-diamond.three {
  animation: sparkle 0.9s infinite;
}

.mousetrap:hover #sparkles .tiny-cross {
  animation: sparkle 1s infinite;
}

.project.open:hover #mousetrap circle {
  fill: var(--darkerProjectColor);
}

.project.open.mousetrap:hover #mousetrap circle {
  fill: var(--lighterProjectColor);
}

.project.open.mousetrap:hover #mousetrap .rind {
  fill: var(--lighterProjectColor);
}

/****** "open" styles  ******/

/* handles mobile text-opening */
.project.open {
  height: 128vw;
  width: 70vw;
  align-items: flex-end;
  background: white;
}

.project.open .project-text-box {
  height: 58vw;
  opacity: 1;
}

/* .project.open:hover {
    border: goldenrod 2px solid;
} */

/* .project.open:hover .project-text-box {
  border-bottom: goldenrod 2px solid;
  border-left: goldenrod 2px solid;
  border-right: goldenrod 2px solid;
} */

.project.open:hover .project-image {
  background: var(--lighterProjectColor);
}

/* handles horizontal text-opening */
.open {
  width: calc(80vw + 12px);
}

.open #speaker-large {
  stroke-width: 6px;
}

.open:hover #speaker-large {
  stroke: var(--lighterProjectColor);
}

.open:hover #speaker-small,
.open:hover #Oval-6 {
  fill: var(--lighterProjectColor);
}

.open:hover #Rectangle-6 {
  fill: var(--lighterProjectColor);
}

#swan circle {
  stroke: #4e4e4e;
}

/* wavy - tuning needle */
@keyframes tuningneedle {
  5% {
    transform: translate(-45px, 0px);
  }
  30% {
    transform: translate(100px, 0px);
  }
}

.wavy:hover #needle {
  animation: tuningneedle 10s ease infinite;
}
.wavy:hover #speaker-small {
  animation: speakerbounce 0.75s ease infinite;
}
.wavy:hover #speaker-small.onscreen {
  animation: speakerbounce 0.75s ease infinite;
}

@keyframes speakerbounce {
  2% {
    transform: scale(1.2) translateX(-18px) translateY(-18px);
    /*     stroke-width: 10; */
  }
  5% {
    transform: scale(1);
    /*     stroke-width: 11; */
  }
  7% {
    transform: scale(1.1) translateX(-10px) translateY(-10px);
    /*     stroke-width: 10; */
  }
  9% {
    transform: scale(1);
    /*     stroke-width: 11; */
  }
  12% {
    transform: scale(1.15) translateX(-16px) translateY(-16px);
    /*     stroke-width: 10; */
  }
  75% {
    transform: scale(1);
    /*     stroke-width: 11; */
  }
  77% {
    transform: scale(1.15) translateX(-16px) translateY(-16px);
    /*     stroke-width: 10; */
  }
}

@media (min-width: 385px) {
  .project-container {
    justify-content: flex-start;
  }

  /* .project.open .project-text-box {
    border-bottom: black 2px solid;
    border-top: black 2px solid;
  }

  .project.open.right .project-text-box {
    border-left: black 2px solid;
    border-right: none;
  }

  .project.open.left .project-text-box {
    border-right: black 2px solid;
    border-left: none;
  } */

  /* .project.open:hover .project-text-box {
    border-bottom: goldenrod 2px solid;
    border-top: goldenrod 2px solid;
  }

  .project.open.right:hover .project-text-box {
    border-left: goldenrod 2px solid;
    border-right: none;
  }

  .project.open.left:hover .project-text-box {
    border-right: goldenrod 2px solid;
  } */

  .project,
  .project-image,
  .project-text-box {
    width: 40vw;
    max-height: 200px;
    height: 42vw;
  }

  .project.open {
    height: 42vw;
    width: 80vw;
  }

  .project.open .project-text-box {
    height: 42vw;
    width: 40vw;
    max-width: 200px;
  }

  .project.open.right .project-text-box {
    justify-self: flex-end;
  }

  .project.open.left .project-text-box {
    justify-self: flex-end;
  }
}

@media (min-width: 620px) {
  .project-container {
    width: 82vw;
    max-width: 420px;
  }

  .project,
  .project-image,
  .project-text {
    max-height: 200px;
    max-width: 200px;
  }

  .project.open {
    max-width: 400px;
  }

  .open {
    width: calc(40vw + 12px);
  }
}

@media (min-width: 760px) {
  .project-container {
    max-width: 847px;
    width: 82vw;
  }
}

.project {
  fill: none;
  stroke-width: 1px;
  stroke: black;
}

.project.open:hover {
  stroke: white;
}
